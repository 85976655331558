.loading-dots {
  width: 100%;
  text-align: center;
  z-index: 5;
  margin-top: 5% !important;
}

.loading-dots > .dot {
  color: rgba(172, 255, 47, 0.692);
  display: inline;
  margin-left: 0.2em;
  margin-right: 0.2em;
  position: relative;
  top: -1em;
  font-size: 3.5em;
  opacity: 0;
  animation: showHideDot 2.5s ease-in-out infinite;
}

.loading-dots > .one { animation-delay: 0.2s; }
.loading-dots > .two { animation-delay: 0.4s; }
.loading-dots > .three { animation-delay: 0.6s; }

@keyframes showHideDot {
  0% { opacity: 0; }
  50% { opacity: 1; }
  60% { opacity: 1; }
  100% { opacity: 0; }
}