.card-list {
    margin-top: 2vh;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    background-color: #0a0a0a;
    border-radius: 10px;
}

.card-list-title{
    width: 100%;
    display: flex;
    flex-direction: row;
}

.card-list-title > a {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    color: rgba(148, 233, 21, 0.842);
    font-size: small;
    font-weight: 600;
}

.card-list-title > a:hover {
    color: rgb(148, 233, 21);
}

.card-list-title > h3 {
    flex: 1;
    text-transform: capitalize;
    margin-left: 2.5vw;
    width: 100%;
    background: linear-gradient(silver ,rgb(255, 255, 255));
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.card-list > div{
    margin: 5px 1.2vw;
}

.card-list > h3{
    text-transform: capitalize;
    margin-left: 2.5vw;
    color:whitesmoke;
    width: 97vw;
}

.card-list .swiper-button-disabled{
    pointer-events: all !important;
}

.card-list .swiper-slide{
    display: flex;
    justify-content: center;
}

.card-list .swiper-pagination{
    height: 1px !important;
}