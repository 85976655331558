/* FOOTER */
footer{
  margin-top: 0;
  height: 15vh;
  display: flex;
  flex-direction: row;
  align-items: center;
  /* background: linear-gradient( to bottom, rgba(0,0,0,0) 0%,rgba(0,0,0,1) 10%); */
}

.footer-butons-container{
  height: 70%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex: 1;
  border-right: solid 1px rgba(192, 192, 192, 0.342);
  font-weight: 800;
}

.footer-button, .footer-link{
  color: silver;
  border-bottom: solid 1px transparent;
  padding: 0 7.5px 2px 7.5px;
  transition: all 0.3s ease-out;
}

.footer-button:hover, .footer-link:hover{
  color: whitesmoke;
  border-color: red;
}

.footer-links-container{
  height: 70%;
  display: flex;
  flex-direction: column;
  color: silver;
  flex: 2;
}

.footer-links-title{
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 800;
}

.footer-links-list{
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 50%;
  font-size: 10px;
}

.footer-title{
  display: none;
}

@media (max-width:600px){
  footer {
    height: 25vh;
    flex-direction: column !important;
  }
  .footer-links-container{
    border-top: solid 1px rgba(192, 192, 192, 0.342);
    width: 100%;
  }
  .footer-butons-container{
    margin-top: 5%;
    border: none;
  }
}