.star { 
  width:104px; 
  position: relative;
  color: #bdbdbd;
}

.rating {
  overflow: hidden;
  white-space: nowrap;
}

.rating > span{
  font-size:25px;
  white-space: nowrap;
  overflow: hidden;
  color: gold;
}

.rating > span:before { 
  content:"\2606\2606\2606\2606\2606";
  position: absolute;
  color: gold;
  ;
}