nav {
  padding-left: 3%;
  height: 10vh;
  width: 100%;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 10;
}

.nav-links {
  margin-right: 12%;
}

.nav-button{
  display: flex;
  justify-content: center;
  width: 135%;
  padding-bottom: 2px;
  color: silver;
  transition: all 0.4s ease-out;
  border-bottom: solid 1px transparent;
}

.nav-button:hover{
  color: whitesmoke;
  border-color: greenyellow;
}


@media (max-width:600px){

  nav{
    background: black;
    height: 90px;
    box-shadow: 0px 0px 5px #ff073a, 0 0 0px #ff073a, 0 0 0px #ff073a, 0 0 0px #ff073a;
  }
}